import React from 'react';

import Diamond from '../../assets/pl-monthly.png';
// Sample data processing



const CandleChart = () => {

  
    return (
      <div className='grapdh-img'>
        <img src={Diamond}/>
      </div>
    );
  };

export default CandleChart;
